



















































































































































import { defineComponent, reactive, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const state = reactive({
      emailExist: null,
      emailUnique: null,
      participantClone: null,
      participantWasPresent: null,
      participantNote: null,
      participantCertificate: null,
      participantSurvey: null,
      participantRegistrationFeeTermDiscount: null,
      participantAccommodationDiscount: null,
      participantServicesDiscount: null,
    });

    watch(
      () => state.emailExist,
      () => {
        if (state.emailExist === "yes") {
          props.value.emailExist.values = "yes";
          props.value.emailExist.operation = "isNotNull";
        } else if (state.emailExist === "no") {
          props.value.emailExist.values = "no";
          props.value.emailExist.operation = "isNull";
        } else {
          props.value.emailExist.values = "";
        }
      },
    );
    watch(
      () => state.emailUnique,
      () => {
        if (state.emailUnique === "yes") {
          props.value.emailUnique.values = "true";
          props.value.emailUnique.operation = "isUnique";
        } else if (state.emailUnique === "no") {
          props.value.emailUnique.values = "false";
          props.value.emailUnique.operation = "isUnique";
        } else {
          props.value.emailUnique.values = "";
        }
      },
    );
    watch(
      () => state.participantClone,
      () => {
        if (state.participantClone === "yes") {
          props.value.participantClone.values = "true";
          props.value.participantClone.operation = "is";
        } else {
          props.value.participantClone.values = "";
        }
      },
    );
    watch(
      () => state.participantWasPresent,
      () => {
        if (state.participantWasPresent === "yes") {
          props.value.participantWasPresent.values = "true";
        } else if (state.participantWasPresent === "no") {
          props.value.participantWasPresent.values = "false";
        } else {
          props.value.participantWasPresent.values = "";
        }
      },
    );
    watch(
      () => state.participantNote,
      () => {
        if (state.participantNote === "yes") {
          props.value.participantNote.values = "yes";
          props.value.participantNote.operation = "isNotNull";
        } else if (state.participantNote === "no") {
          props.value.participantNote.values = "no";
          props.value.participantNote.operation = "isNull";
        } else {
          props.value.participantNote.values = "";
        }
      },
    );
    watch(
      () => state.participantCertificate,
      () => {
        if (state.participantCertificate === "yes") {
          props.value.participantCertificate.values = "yes";
          props.value.participantCertificate.operation = "isNotNull";
        } else if (state.participantCertificate === "no") {
          props.value.participantCertificate.values = "no";
          props.value.participantCertificate.operation = "isNull";
        } else {
          props.value.participantCertificate.values = "";
        }
      },
    );
    watch(
      () => state.participantSurvey,
      () => {
        if (state.participantSurvey === "yes") {
          props.value.participantSurvey.values = "yes";
          props.value.participantSurvey.operation = "isNotNull";
        } else if (state.participantSurvey === "no") {
          props.value.participantSurvey.values = "no";
          props.value.participantSurvey.operation = "isNull";
        } else {
          props.value.participantSurvey.values = "";
        }
      },
    );
    watch(
      () => state.participantRegistrationFeeTermDiscount,
      () => {
        if (state.participantRegistrationFeeTermDiscount === "yes") {
          props.value.participantRegistrationFeeTermDiscount.values = "yes";
          props.value.participantRegistrationFeeTermDiscount.operation =
            "isNotNull";
        } else if (state.participantRegistrationFeeTermDiscount === "no") {
          props.value.participantRegistrationFeeTermDiscount.values = "no";
          props.value.participantRegistrationFeeTermDiscount.operation =
            "isNull";
        } else {
          props.value.participantRegistrationFeeTermDiscount.values = "";
        }
      },
    );
    watch(
      () => state.participantAccommodationDiscount,
      () => {
        if (state.participantAccommodationDiscount === "yes") {
          props.value.participantAccommodationDiscount.values = "yes";
          props.value.participantAccommodationDiscount.operation = "isNotNull";
        } else if (state.participantAccommodationDiscount === "no") {
          props.value.participantAccommodationDiscount.values = "no";
          props.value.participantAccommodationDiscount.operation = "isNull";
        } else {
          props.value.participantAccommodationDiscount.values = "";
        }
      },
    );
    watch(
      () => state.participantServicesDiscount,
      () => {
        if (state.participantServicesDiscount === "yes") {
          props.value.participantServicesDiscount.values = "yes";
          props.value.participantServicesDiscount.operation = "isNotNull";
        } else if (state.participantServicesDiscount === "no") {
          props.value.participantServicesDiscount.values = "no";
          props.value.participantServicesDiscount.operation = "isNull";
        } else {
          props.value.participantServicesDiscount.values = "";
        }
      },
    );
    return { state };
  },
});
